import React, {useEffect, useState} from 'react';
import ContentHeader from "../ContentHeader";
import {useSelector} from "react-redux";
import {
    useParams
} from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

export default function ErrorNotice() {
    const failure = useSelector(state => state.failure);
    const [open,setOpen] = useState(false)

    useEffect(() => {
        if(failure.payload.code !== 3603){
            setOpen(failure.meta.isShow);
        }
    },[failure]);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        failure.meta.isShow ?
            <Snackbar
                anchorOrigin={{horizontal: "center",vertical: "top"}}
                open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {failure.payload.message}
                </Alert>
            </Snackbar>
                :
            null
    );
};
