import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {Create} from "@material-ui/icons";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import TableContainer from "@material-ui/core/TableContainer";
import * as Actions from "../../flex/Actions";
import Chip from '@material-ui/core/Chip';
import TemplateEditDialog from "./TemplateEditDialog";
import TemplateDeleteDialog from "./TemplateDeleteDialog";
import Tooltip from "@material-ui/core/Tooltip";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import moment from "moment";


const useStyles = makeStyles(theme => ({
    alert: {
        marginBottom: theme.spacing(2)
    },
    table: {
        minWidth: 1200,
    },
    actionHeader: {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: theme.spacing(2),
    },
    margin: {
        marginRight: theme.spacing(1),
    },
}));


export default function TopicsInterface() {
    const classes = useStyles();
    let {id} = useParams();
    const dispatch = useDispatch();
    const templateList = useSelector(state => state.templateList);
    const templateGetConnection = useSelector(state => state.templateGetConnection)

    const [isOpenEditDialog, setOpenEditDialog] = useState(false);
    const [isOpenDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isEditMode, setEditMode] = useState(false);
    const [data, setData] = useState({});
    const [array, setArray] = useState([]);

    //template取得部の終了の際、利用するarrayのパラメータを変更
    useEffect(() => {
        console.log(templateList);
        setArray(templateList.payload);
    }, [templateList])

    //id要素があれたときにtopicsを再読み込み
    useEffect(() => {
        dispatch(Actions.http.connection.templates.getTemplates())
    }, []);

    return (
        <>
            <div className={classes.alert}>
                <Alert severity="info">
                    <AlertTitle>テンプレートとは</AlertTitle>
                    <span>
                        配信テンプレートを使用することであらかじめ作成された記事内容を編集して新しい記事を作成することができます。<br />
                        イベント情報の告知や休講情報などの連絡など定型文を何度も使用して記事を作成する場合に便利です。
                    </span>
                </Alert>
            </div>
            <div className={classes.actionHeader}>
                <Button
                    variant="contained"
                    className={classes.button}
                    startIcon={<Create/>}
                    color={"primary"}
                    disableElevation
                    onClick={() => {
                        setEditMode(false);
                        setOpenEditDialog(true)
                    }}>
                    新しいテンプレートを作成
                </Button>
            </div>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell width={60}>No</TableCell>
                            <TableCell>タイトル</TableCell>
                            <TableCell>備考</TableCell>
                            <TableCell width={200}>追加日/更新日</TableCell>
                            <TableCell width={80}> </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {array.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    {row.TEMPLATE_ID}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.TEMPLATE_TITLE}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.TEMPLATE_DESCRIPTION}
                                </TableCell>
                                <TableCell>
                                    {moment(row.TEMPLATE_CREATED).format("YYYY-MM-DD HH:mm")}<br/>
                                    {moment(row.TEMPLATE_UPDATED).format("YYYY-MM-DD HH:mm")}
                                </TableCell>
                                <TableCell align="right">

                                    <Tooltip title="編集" aria-label="edit">
                                        <IconButton aria-label="edit" className={classes.margin}
                                                    size={"small"}
                                                    onClick={() => {
                                                        setData(row);
                                                        setEditMode(true);
                                                        setOpenEditDialog(true);
                                                    }
                                                    }>
                                            <EditIcon/>
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="削除" aria-label="delete">
                                        <IconButton aria-label="delete" className={classes.margin}
                                                    size={"small"}
                                                    onClick={() => {
                                                        setData(row);
                                                        setOpenDeleteDialog(true)
                                                    }}>
                                            <DeleteForeverIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TemplateEditDialog isEditMode={isEditMode} data={data} isOpen={isOpenEditDialog}
                                changeOpenState={(value) => setOpenEditDialog(value)}/>

            <TemplateDeleteDialog data={data} isOpen={isOpenDeleteDialog}
                                  changeOpenState={(value) => setOpenDeleteDialog(value)}/>

        </>
    );
}