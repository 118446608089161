import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import EditIcon from '@material-ui/icons/Edit';

import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import CategoryIcon from '@material-ui/icons/Category';
import {useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';
import MenuHeader from "./MenuHeader";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import SettingsIcon from '@material-ui/icons/Settings';

const drawerWidth = 270;

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    }
}));

export default function LeftMenu() {
    const classes = useStyles();

    const categoryList = useSelector(state => state.categoryList);
    const [selectedIndex, setSelectedIndex] = React.useState(1);

    const history = useHistory();

    const handleListItemClick = (event, category_id) => {
        setSelectedIndex(category_id);
        if(category_id === "category"){
            history.push("/category")
        }else if(category_id === "template") {
            history.push("/template")
        }else if(category_id === "settings"){
            history.push("/settings")
        }else{
            history.push(`/topics/${category_id}`)
        }
//        history.push("/param");
    };

    return (
        <Drawer
            variant="permanent"
            classes={{
                paper: clsx(classes.drawerPaper),
            }}
            open={true}>
            <div className={classes.root}>
                <MenuHeader />
                <Divider />
                <List component="nav" aria-label="main mailbox folders">
                    <ListItem
                        button
                        selected={selectedIndex === "category"}
                        onClick={event => handleListItemClick(event, "category")}
                    >
                        <ListItemIcon>
                            <CreateNewFolderIcon />
                        </ListItemIcon>
                        <ListItemText primary="カテゴリ編集" />
                    </ListItem>

                    <ListItem
                        button
                        selected={selectedIndex === "template"}
                        onClick={event => handleListItemClick(event, "template")}
                    >
                        <ListItemIcon>
                            <EditIcon />
                        </ListItemIcon>
                        <ListItemText primary="テンプレート管理" />
                    </ListItem>
                </List>
                <Divider />

                {categoryList.payload.map((value,index) => {
                    return (
                        <ListItem
                            key={index}
                            button
                            selected={selectedIndex === value.CATEGORY_ID}
                            onClick={event => handleListItemClick(event, value.CATEGORY_ID)}
                        >
                            <ListItemIcon>
                                <ChevronRightIcon />
                            </ListItemIcon>
                            <ListItemText primary={value.CATEGORY_NAME} />
                        </ListItem>
                    )
                })}

                <Divider />

                <ListItem
                    button
                    selected={selectedIndex === "settings"}
                    onClick={event => handleListItemClick(event, "settings")}
                >
                    <ListItemIcon>
                        <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="設定" />
                </ListItem>
            </div>
            <Divider/>
        </Drawer>

    );
}