import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import * as Actions from "../../flex/Actions";
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from "@material-ui/core/FormControl";
import {makeStyles} from "@material-ui/styles";
import InputLabel from "@material-ui/core/InputLabel";
import {useParams} from "react-router-dom";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";

import "moment/locale/ja";
import TopicFroalaEditor from "./TopicFroalaEditor";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import {DropzoneArea} from "material-ui-dropzone";
import * as Helper from "../../utils/Helper";
import Chip from "@material-ui/core/Chip";
import ProgressButton from "../../utils/ProgressButton";
import {TopicPatrolledEnum} from "../../utils/Helper";

moment.locale("ja");

const useStyles = makeStyles(theme => ({
    formControl: {
        marginBottom: theme.spacing(3),
    },
    flex: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    flexColumn: {
        display: "flex",
        flexDirection: "column"
    },
    flexSpacer: {
        width: "20px",
        height: "auto"
    },
    labelSpacer: {
        height: "20px",
        width: "100%"
    }
}));


const topicTypeEnum = {
    PDF: "PDF",
    NORMAL: "NORMAL"
};
const pdfViewerTypeEnum = {
    NORMAL: "0",
    SPREAD: "1"
}

export default function TopicEditDialog(props) {
    let {id} = useParams();
    const classes = useStyles();
    const dispatch = useDispatch();
    const categoryList = useSelector(state => state.categoryList);
    const topicEditConnection = useSelector(state => state.topicEditConnection)
    const user = useSelector(state => state.user);
    //記事名
    const [topicTitle, setTopicTitle] = useState("");
    //記事カテゴリ
    const [topicCategory, setTopicCategory] = useState(id);
    //記事作成者
    const [topicCreatorName, setTopicCreatorName] = useState("");
    //記事の備考
    const [topicDescription, setTopicDescription] = useState("");
    //本文
    const [topicBody, setTopicBody] = useState("");
    //期日設定 フロム
    const [topicAvailableFromDate, setTopicAvailableFromDate] = useState(new Date());
    //期日設定　トゥー
    const [topicAvailableToDate, setTopicAvailableToDate] = useState(new Date());
    //アタッチのタイプ 0の場合は画像+本文、１の場合はPDF
    const [topicAttachType, setTopicAttachType] = useState(topicTypeEnum.NORMAL);
    //添付ファイル名
    const [topicAttachedComment, setTopicAttachedComment] = useState("");
    //ファイル群
    const [files, setFiles] = useState([]);
    //pdfのアタッチタイプ
    const [pdfViewerType, setPdfViewerType] = useState("0");
    //読み込み中
    const [loading,setLoading] = useState(false);
    //承認状態
    const [topicPatrolled, setTopicPatrolled] = useState(TopicPatrolledEnum.AUTO_ACCEPTED);
    //ユーザ権限持ちかどうか
    const [isPatroller , setIsPatroller] = useState(false);


    useEffect(() => {
        if (props.isEditMode) {
            setTopicTitle(props.data.TOPIC_TITLE);
            setTopicBody(props.data.TOPIC_BODY);
            setTopicDescription(props.data.TOPIC_DESCRIPTION ? props.data.TOPIC_DESCRIPTION : "");
            setTopicCreatorName(props.data.TOPIC_CREATOR_NAME);
            setTopicAvailableFromDate(moment(props.data.TOPIC_AVAILABLE_FROM).format('YYYY-MM-DD'));
            setTopicAvailableToDate(moment(props.data.TOPIC_AVAILABLE_TO).format('YYYY-MM-DD'));
            setTopicAttachType(props.data.TOPIC_PDF_ATTACH === "1" ? topicTypeEnum.PDF : topicTypeEnum.NORMAL);
            setPdfViewerType(props.data.TOPIC_PDF_TWO_PAGES_OPPOSITE_EACH_OTHER === "1" ? pdfViewerTypeEnum.SPREAD : pdfViewerTypeEnum.NORMAL);
            setTopicAttachedComment(props.data.TOPIC_IMAGE_COMMENT);
            setTopicPatrolled(props.data.TOPIC_PATROLLED);
            setFiles([]);
        } else {
            setTopicTitle("");
            const template = props.template ? props.template : "";
            setTopicBody(template);
            setTopicDescription("");
            setTopicCreatorName("");
            setTopicAvailableFromDate(moment().format('YYYY-MM-DD'));
            setTopicAvailableToDate(moment().format('YYYY-MM-DD'));
            setTopicAttachType(topicTypeEnum.NORMAL);
            setPdfViewerType(pdfViewerTypeEnum.NORMAL);
            setTopicAttachedComment("");
            setTopicPatrolled(TopicPatrolledEnum.AUTO_ACCEPTED);
            setFiles([]);
        }
    }, [props.isEditMode, props.data, props.template]);

    useEffect(()=>{
        console.log(user);
        if(user.payload.hasOwnProperty("ROLE_ID") && user.payload.ROLE_ID === "0"){
            console.log("true")
            setIsPatroller(true);
        }else{
            console.log("false")
            setIsPatroller(false);
        }
    },[user])

    useEffect(() => {
        if (topicEditConnection.meta.status === Actions.statusEnum.SUCCESS && topicEditConnection.payload.response === "success") {
            handleClose()
        }
        setLoading(topicEditConnection.meta.fetch)
    }, [topicEditConnection])

    useEffect(() => {
        setTopicCategory(id);
    }, [id]);

    const createTopic = () => {
        if (topicAttachType === topicTypeEnum.NORMAL) {
            //ノーマル記事
            const postData = {
                CATEGORY_ID: topicCategory,
                TOPIC_TITLE: topicTitle,
                TOPIC_BODY: topicBody,
                TOPIC_CREATOR_NAME: topicCreatorName,
                TOPIC_DESCRIPTION: topicDescription,
                TOPIC_AVAILABLE_FROM: moment(topicAvailableFromDate).format('YYYY-MM-DD'),
                TOPIC_AVAILABLE_TO: moment(topicAvailableToDate).format('YYYY-MM-DD'),
                TOPIC_IMAGE_ATTACH: "0", //恐らく0なら何もアタッチしない
                TOPIC_IMAGE_COMMENT: "",
            };
            if(isPatroller){
                postData.TOPIC_PATROLLED = topicPatrolled;
            }
            dispatch(Actions.http.connection.topics.createTopic(postData));
        } else {
            //PDF添付
            console.log(files);
            Helper.convertBase64FromFiles(files[0])
                .then((value) => {
                    const postData = {
                        CATEGORY_ID: topicCategory,
                        TOPIC_TITLE: topicTitle,
                        TOPIC_BODY: topicBody,
                        TOPIC_CREATOR_NAME: topicCreatorName,
                        TOPIC_DESCRIPTION: topicDescription,
                        TOPIC_AVAILABLE_FROM: moment(topicAvailableFromDate).format('YYYY-MM-DD'),
                        TOPIC_AVAILABLE_TO: moment(topicAvailableToDate).format('YYYY-MM-DD'),

                        TOPIC_IMAGE_ATTACH: "0", //恐らく0なら何もアタッチしない
                        TOPIC_IMAGE_COMMENT: files[0].name,

                        TOPIC_PDF_ATTACH: "1",
                        TOPIC_PDF_TWO_PAGES_OPPOSITE_EACH_OTHER: pdfViewerType,
                        TOPIC_PDF_BASE64: value,
                    };
                    if(isPatroller){
                        postData.TOPIC_PATROLLED = topicPatrolled;
                    }
                    dispatch(Actions.http.connection.topics.createTopic(postData));
                })
                .catch((error) => {
                    //todo base64変換に失敗した汎用エラー処理
                });


        }
    };

    const editTopic = () => {

        if (topicAttachType === topicTypeEnum.NORMAL) {
            //通常記事
            const postData = {
                TOPIC_ID: props.data.TOPIC_ID,
                CATEGORY_ID: topicCategory,
                TOPIC_TITLE: topicTitle,
                TOPIC_BODY: topicBody,
                TOPIC_DESCRIPTION: topicDescription,
                TOPIC_CREATOR_NAME: topicCreatorName,
                TOPIC_AVAILABLE_FROM: moment(topicAvailableFromDate).format('YYYY-MM-DD'),
                TOPIC_AVAILABLE_TO: moment(topicAvailableToDate).format('YYYY-MM-DD'),
                TOPIC_IMAGE_ATTACH: "0", //恐らく0なら何もアタッチしない
                TOPIC_IMAGE_COMMENT: "",
                TOPIC_PATROLLED: topicPatrolled
            };
            dispatch(Actions.http.connection.topics.editTopic(postData));
        } else {
            //PDF添付
            if (files.length === 0) {
                //ファイルの変更が行われた形跡がない
                console.log(topicAttachedComment);
                if (topicAttachedComment) {
                    //なおかつファイルが削除されていない場合
                    const postData = {
                        TOPIC_ID: props.data.TOPIC_ID,
                        CATEGORY_ID: topicCategory,
                        TOPIC_TITLE: topicTitle,
                        TOPIC_BODY: topicBody,
                        TOPIC_DESCRIPTION: topicDescription,
                        TOPIC_CREATOR_NAME: topicCreatorName,
                        TOPIC_AVAILABLE_FROM: moment(topicAvailableFromDate).format('YYYY-MM-DD'),
                        TOPIC_AVAILABLE_TO: moment(topicAvailableToDate).format('YYYY-MM-DD'),
                        TOPIC_IMAGE_COMMENT: topicAttachedComment
                    };
                    if(isPatroller){
                        postData.TOPIC_PATROLLED = topicPatrolled;
                    }
                    dispatch(Actions.http.connection.topics.editTopic(postData));
                } else {
                    //なおかつファイルが削除されていると考えられる場合
                    const postData = {
                        TOPIC_ID: props.data.TOPIC_ID,
                        CATEGORY_ID: topicCategory,
                        TOPIC_TITLE: topicTitle,
                        TOPIC_BODY: topicBody,
                        TOPIC_DESCRIPTION: topicDescription,
                        TOPIC_CREATOR_NAME: topicCreatorName,
                        TOPIC_AVAILABLE_FROM: moment(topicAvailableFromDate).format('YYYY-MM-DD'),
                        TOPIC_AVAILABLE_TO: moment(topicAvailableToDate).format('YYYY-MM-DD'),
                        TOPIC_IMAGE_ATTACH: "0", //恐らく0なら何もアタッチしない
                        TOPIC_IMAGE_COMMENT: topicAttachedComment,
                        TOPIC_PDF_ATTACH: "0"
                    };
                    if(isPatroller){
                        postData.TOPIC_PATROLLED = topicPatrolled;
                    }
                    dispatch(Actions.http.connection.topics.editTopic(postData));
                }
            } else {
                //ファイルが新しくなっている場合
                Helper.convertBase64FromFiles(files[0])
                    .then((value) => {
                        const postData = {
                            TOPIC_ID: props.data.TOPIC_ID,
                            CATEGORY_ID: topicCategory,
                            TOPIC_TITLE: topicTitle,
                            TOPIC_BODY: topicBody,
                            TOPIC_DESCRIPTION: topicDescription,
                            TOPIC_CREATOR_NAME: topicCreatorName,
                            TOPIC_AVAILABLE_FROM: moment(topicAvailableFromDate).format('YYYY-MM-DD'),
                            TOPIC_AVAILABLE_TO: moment(topicAvailableToDate).format('YYYY-MM-DD'),

                            TOPIC_IMAGE_ATTACH: "0", //恐らく0なら何もアタッチしない
                            TOPIC_IMAGE_COMMENT: files[0].name,

                            TOPIC_PDF_ATTACH: "1",
                            TOPIC_PDF_TWO_PAGES_OPPOSITE_EACH_OTHER: pdfViewerType,
                            TOPIC_PDF_BASE64: value,
                        };
                        dispatch(Actions.http.connection.topics.editTopic(postData));
                    })
                    .catch((error) => {
                        //todo base64変換に失敗した汎用エラー処理
                    });
            }
        }
    };

    const handleClose = () => {
        props.changeOpenState(false)
    };

    const handleSubmit = () => {
        if (props.isEditMode) {
            editTopic();
        } else {
            createTopic();
        }
    };

    return (
        <>
            <Dialog open={props.isOpen} onClose={handleClose} aria-labelledby="form-dialog-title"
                    fullScreen={true}
                    maxWidth={"sm"}
                    fullWidth={true}>

                <form onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                }}>
                    <DialogTitle id="form-dialog-title">{props.isEditMode ? "記事の編集" : "記事の新規作成"}</DialogTitle>
                    <DialogContent>
                        <FormControl fullWidth={true} component="fieldset" className={classes.formControl}>
                            <TextField
                                InputLabelProps={{shrink: true}}
                                autoFocus
                                required
                                margin="dense"
                                id="category"
                                label="記事名"
                                fullWidth
                                value={topicTitle}
                                onChange={(event) => {
                                    setTopicTitle(event.target.value)
                                }}
                            />
                        </FormControl>


                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={"ja"}>
                            <FormControl fullWidth={true} component="fieldset" className={classes.formControl}>
                                <div className={classes.flex}>
                                    <DatePicker
                                        fullWidth
                                        label="配信期日 開始日"
                                        value={topicAvailableFromDate}
                                        onChange={date => setTopicAvailableFromDate(date)}
                                        format="YYYY/MM/DD"
                                        maxDate={topicAvailableToDate}
                                        InputProps={{}}
                                    />
                                    <div className={classes.flexSpacer}/>
                                    <DatePicker
                                        fullWidth
                                        label="配信期日 終了日"
                                        value={topicAvailableToDate}
                                        onChange={date => setTopicAvailableToDate(date)}
                                        format="YYYY/MM/DD"
                                        minDate={topicAvailableFromDate}
                                        InputProps={{}}
                                    />
                                </div>

                            </FormControl>
                        </MuiPickersUtilsProvider>

                        <FormControl fullWidth={true} component="fieldset" className={classes.formControl}>
                            <InputLabel shrink>
                                カテゴリ
                            </InputLabel>
                            <Select
                                required
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={topicCategory}
                                label="カテゴリ選択"
                                onChange={(event) => {
                                    setTopicCategory(event.target.value)
                                }}
                            >
                                {categoryList.payload.map((value, index) => {
                                    return (
                                        <MenuItem key={index} value={value.CATEGORY_ID}>{value.CATEGORY_NAME}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth={true} component="fieldset" className={classes.formControl}>
                            <TextField
                                required
                                InputLabelProps={{shrink: true}}
                                autoFocus
                                margin="dense"
                                id="author"
                                label="配信元"
                                fullWidth
                                value={topicCreatorName}
                                onChange={(event) => {
                                    setTopicCreatorName(event.target.value)
                                }}
                            />
                        </FormControl>

                        <FormControl fullWidth={true} component="fieldset" className={classes.formControl}>
                            <TextField
                                InputLabelProps={{shrink: true}}
                                autoFocus
                                margin="dense"
                                id="topic_description"
                                label="備考・メモ(サイネージには表示されません)"
                                fullWidth
                                value={topicDescription}
                                onChange={(event) => {
                                    setTopicDescription(event.target.value)
                                }}
                            />
                        </FormControl>

                        <FormControl fullWidth={true} component={"div"} className={classes.formControl}>
                            <InputLabel shrink>
                                記事種別
                            </InputLabel>
                            <div className={classes.labelSpacer}/>
                            <RadioGroup aria-label="position" name="position" value={topicAttachType}
                                        onChange={(event) => {
                                            setTopicAttachType(event.target.value)
                                        }} row>
                                <FormControlLabel
                                    value={topicTypeEnum.NORMAL}
                                    control={<Radio color="primary"/>}
                                    label="通常記事"
                                />
                                <FormControlLabel
                                    value={topicTypeEnum.PDF}
                                    control={<Radio color="primary"/>}
                                    label="PDF添付"
                                />
                            </RadioGroup>
                        </FormControl>

                        {isPatroller ?
                            <FormControl fullWidth={true} component={"div"} className={classes.formControl}>
                                <InputLabel shrink>
                                    承認
                                </InputLabel>
                                <div className={classes.labelSpacer}/>
                                <RadioGroup aria-label="position" name="position" value={topicPatrolled}
                                            onChange={(event) => {
                                                setTopicPatrolled(event.target.value)
                                            }} row>
                                    <FormControlLabel
                                        value={TopicPatrolledEnum.NOTYET}
                                        control={<Radio color="primary"/>}
                                        label="未確認"
                                    />
                                    <FormControlLabel
                                        value={TopicPatrolledEnum.ACCEPTED}
                                        control={<Radio color="primary"/>}
                                        label="承認"
                                    />
                                    <FormControlLabel
                                        value={TopicPatrolledEnum.REJECTED}
                                        control={<Radio color="primary"/>}
                                        label="却下"
                                    />
                                    <FormControlLabel
                                        value={TopicPatrolledEnum.AUTO_ACCEPTED}
                                        control={<Radio color="primary"/>}
                                        label="自動承認"
                                    />
                                </RadioGroup>
                            </FormControl>
                            :
                            null
                        }

                        {topicAttachType === topicTypeEnum.NORMAL ?
                            <FormControl fullWidth={true} component="fieldset" className={classes.formControl}>
                                <InputLabel shrink>
                                    本文
                                </InputLabel>
                                <div className={classes.flexColumn}>
                                    <div className={classes.labelSpacer}/>
                                    <TopicFroalaEditor
                                        value={topicBody}
                                        onChange={(value) => {
                                            setTopicBody(value);
                                        }
                                        }/>
                                </div>
                            </FormControl>
                            :
                            <>
                                <FormControl fullWidth={true} component="fieldset" className={classes.formControl}>
                                    <InputLabel shrink>
                                        PDF添付
                                    </InputLabel>
                                    {topicAttachedComment ?
                                        <div>
                                            <div className={classes.labelSpacer}/>
                                            <Chip
                                                label={topicAttachedComment}
                                                onDelete={() => {
                                                    setTopicAttachedComment("")
                                                }}
                                                variant="outlined"
                                            />
                                        </div>
                                        :
                                        <DropzoneArea
                                            maxFileSize={100000000}
                                            acceptedFiles={["application/pdf"]}
                                            filesLimit={1}
                                            dropzoneText={"クリックするかここにドラッグ&ドロップ"}
                                            useChipsForPreview={true}
                                            onChange={(files) => setFiles(files)}
                                            showFileNamesInPreview={true}
                                            showPreviews={true}
                                            showPreviewsInDropzone={false}
                                        />
                                    }
                                </FormControl>

                                {props.isEditMode ?
                                    null :
                                    <FormControl fullWidth={true} component={"div"} className={classes.formControl}>
                                        <InputLabel shrink>
                                            PDF表示種別
                                        </InputLabel>
                                        <div className={classes.labelSpacer}/>
                                        <RadioGroup aria-label="position" name="position" value={pdfViewerType}
                                                    onChange={(event) => {
                                                        setPdfViewerType(event.target.value)
                                                    }} row>
                                            <FormControlLabel
                                                value={pdfViewerTypeEnum.NORMAL}
                                                control={<Radio color="primary"/>}
                                                label="通常"
                                            />
                                            <FormControlLabel
                                                value={pdfViewerTypeEnum.SPREAD}
                                                control={<Radio color="primary"/>}
                                                label="見開き"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                }
                            </>
                        }

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            キャンセル
                        </Button>
                        <ProgressButton type={"submit"} color="primary" loading={loading}>
                            {props.isEditMode ? "変更" : "作成"}
                        </ProgressButton>
                    </DialogActions>

                </form>
            </Dialog>
        </>
    );
}


TopicEditDialog.propTypes = {
    isEditMode: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool.isRequired,
    changeOpenState: PropTypes.func.isRequired
};


TopicEditDialog.defaultProps = {
    isEditMode: false,
    isOpen: false,
    changeOpenState: () => console.warn("need changeOpenState func")
};