import React, {useEffect, useState} from 'react'

import 'froala-editor/js/languages/ja.js';

import "froala-editor/js/plugins/image.min.js"
import "froala-editor/js/plugins/image_manager.min.js"
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import "froala-editor/js/plugins/font_family.min.js";
import "froala-editor/js/plugins/link.min.js";
import "froala-editor/js/plugins/print.min.js"

import "froala-editor/css/plugins/image.min.css";
import "froala-editor/css/plugins/image_manager.min.css";
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

import FroalaEditorComponent from 'react-froala-wysiwyg';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import {makeStyles} from "@material-ui/styles";
import {useDispatch, useSelector} from "react-redux";
import * as Actions from "../../flex/Actions";
import S3InfoEffectComponent from "../../EffectComponent/S3InfoEffectComponent";
import Button from "@material-ui/core/Button";
import Skeleton from "@material-ui/lab/Skeleton";


const useStyles = makeStyles(theme => ({
    formControl: {
        marginBottom: theme.spacing(3),
    },
    flex: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    flexSpacer: {
        width: "20px",
        height: "auto"
    }
}));


export default function TopicFroalaEditor(props) {
    const [model, setModel] = useState("");
    const [imageUploadToS3, setImageUploadToS3] = useState({});
    const dispatch = useDispatch();
    const s3Info = useSelector(state => state.s3Info);
    const s3InfoConnection = useSelector(state => state.s3InfoConnection);
    const [isReady, setIsReady] = useState(false)

    useEffect(() => {
        dispatch(Actions.http.connection.utils.getS3Info());
    }, []);

    useEffect(() => {
        setImageUploadToS3(s3Info.payload);
    }, [s3Info]);

    useEffect(() => {
        //imageUploadToS3のパラメータの正常格納が終了することを確認した時点で用意終了
        if (imageUploadToS3.hasOwnProperty("bucket")) {
            setIsReady(true);
        }
    }, [imageUploadToS3])


    return (
        <>
            <S3InfoEffectComponent/>
            {!s3InfoConnection.meta.fetch ?
                isReady ?
                    <FroalaEditorComponent
                        tag='textarea'
                        model={props.value}
                        onModelChange={(value) => props.onChange(value)}
                        config={{
                            zIndex: 1400,//これが乗っかるDialogが実はすでにzIndexが1300だから注意しといたほうがもしかしたらいいかもしれない
                            toolbarButtons: {
                                'moreText': {
                                    'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
                                },
                                'moreParagraph': {
                                    'buttons': ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
                                },
                                'moreRich': {
                                    'buttons': ['insertLink', 'insertImage', 'insertHR']
                                },
                                'moreMisc': {
                                    'buttons': ['undo', 'redo', 'fullscreen', 'selectAll', 'html', 'help'],
                                    'align': 'right',
                                    'buttonsVisible': 2
                                }
                            },
                            linkEditButtons: ['linkOpen','linkStyle', 'linkRemove'],
                            linkList: [
                                {
                                    text: 'Google',
                                    href: 'http://google.com',
                                    target: '_blank',
                                    rel: 'nofollow'
                                }
                            ],
                            quickInsertButtons: ['image', 'table', 'ul', 'ol', 'hr'],
                            language: 'ja',
                            imageUploadToS3: {...imageUploadToS3},
                            imageResizeWithPercent: true,
                            imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
                            imageEditButtons: ['imageAlign', 'imageDisplay', '|', 'imageRemove'],
                            videoUpload: false,
                            fontSizeSelection: true,
                            fontsizeDefaultSelection: "24",
                            fontSize: ['18', '24', '30', '60', '96'],
                            fontSizeUnit: "px",
                            key: "BWC6D-16E4C2E2D2ta3CGHNOd1TNSPC1d2E1VLPUUCVc2FC-11A2B2C2H2C9B3C4C6A1F1==",
                            attribution: false
                        }}
                    />
                    :
                    <Button onClick={() => dispatch(Actions.http.connection.utils.getS3Info())}>再読込</Button>
                :
                <Skeleton variant="text"/>
            }
        </>
    )
}