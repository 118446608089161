import React, {useReducer, useContext, useState, useEffect} from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory,
    useLocation
} from "react-router-dom";
import {Skeleton} from '@material-ui/lab';
import Main from "../Main";
import Login from "../login/Login";
import {useDispatch, useSelector} from "react-redux";
import LoginInterface from "../login/LoginInterface";
import AuthEffectComponent from "../../EffectComponent/AuthEffectComponent";
import * as Actions from "../../flex/Actions";

export default function Auth() {
    const dispatch = useDispatch();
    dispatch(Actions.http.connection.authentication.current())
    return (
        <Router>
            <div>
                <Switch>
                    <Route path="/login">
                        <PublicPage/>
                    </Route>
                    <PrivateRoute>
                        <ProtectedPage/>
                    </PrivateRoute>
                </Switch>
                <AuthEffectComponent/>
            </div>
        </Router>
    );
}

function PrivateRoute({children, ...rest}) {
    const session = useSelector(state => state.session);
    const loginConnection = useSelector(state => state.loginConnection);

    return (
        loginConnection.meta.fetch || !session.payload.is_checked ?
            <Skeleton variant="text"/>
            :
            <Route
                {...rest}
                render={({location}) =>
                    session.payload.is_authenticated ? (
                        children
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: {from: location}
                            }}
                        />
                    )
                }
            />
    );
}

//未認証時のページ
function PublicPage({children, ...rest}) {
    const session = useSelector(state => state.session);
    const loginConnection = useSelector(state => state.loginConnection);

    return (
        loginConnection.meta.fetch || !session.payload.is_checked ?
            <Skeleton variant="text"/>
            :
            <Route
                {...rest}
                render={({location}) =>
                    session.payload.is_authenticated ? (
                        <Redirect
                            to={{
                                pathname: "/home",
                                state: {from: location}
                            }}
                        />
                    ) : (
                        <Login/>
                    )
                }
            />
    )
}

//認証時のページ
function ProtectedPage() {
    return (
        <Main/>
    )
}

