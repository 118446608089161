import React, {useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {useDispatch, useSelector} from "react-redux";
import * as Actions from "../../flex/Actions";
import packageJson from '../../../package.json';

import {ReactComponent as ServiceLogo} from './ksf_logo.svg';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'KnowledgeSense SignageManager Ver.'}{packageJson.version}<br />
            {' Copyright © '}{new Date().getFullYear()}{' '}
            <a href={"https://siba-service.jp/"} style={{textDecoration:"none",color:"#555555"}}>Siba Service</a>
            {' Inc. All Rights Reserved.'}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {
        //marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: "center",
        width: "100%",
        minHeight: "85vh",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));


function submit() {
}

export default function LoginInterface() {

    const loginConnection = useSelector(state => state.loginConnection);

    useEffect(() => {
        console.log(loginConnection);
        console.log("useEffect")
    }, [loginConnection]);


    const classes = useStyles();
    const [userId, setUserId] = useState("");
    const [password, setPassword] = useState("")

    const dispatch = useDispatch();


    const sub = (e) => {
        e.preventDefault();
        const param = {
            USER_ID: userId,
            PASSWORD: password,
        }
        dispatch(Actions.http.connection.authentication.login(param))
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <ServiceLogo style={{width: "auto", height: 100, padding: "10px"}} />
                <Typography component="h1" variant="h5" style={{color: "#444444"}}>
                    サイネージマネージャー
                </Typography>
                <form className={classes.form} onSubmit={sub} noValidate autoComplete="off">
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        size="small"
                        id="login_id"
                        label="ログインID"
                        name="login_id"
                        autoFocus
                        value={userId}
                        onChange={(e) => {
                            setUserId(e.target.value)
                        }}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        size="small"
                        name="パスワード"
                        label="パスワード"
                        type="password"
                        id="パスワード"
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value)
                        }}
                    />

                    <Button
                        type="submit"
                        size="large"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disableElevation
                        className={classes.submit}>
                        サインイン
                    </Button>
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}
