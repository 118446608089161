import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@material-ui/styles";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

const useStyles = makeStyles({
    defaultMessage: {
        width: "100%",
        minHeight: "85vh",
        justifyContent: "center",
        display: "flex",
        alignItems: "center"
    },
    messageMain: {
        margin: "0",
        fontSize: "1.8em"
    }
});

export default function ContentNavigation() {

    const classes = useStyles();

    return (
        <>
            <div className={classes.defaultMessage}>
                <div className={classes.messageMain}>
                    <Alert severity="info">
                        <AlertTitle>メニューの選択</AlertTitle>
                        左サイドバーから利用するメニューを選択してください
                    </Alert>
                </div>
            </div>
        </>
    );
}
