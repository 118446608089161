import * as Actions from "./Actions";
import { apiAccess } from "../apis/AxiosWrapper"
import { useHistory } from "react-router-dom";


const filteringFailedConnection = (next,action,response,store) =>{
    //通常の200返答でもfailedで返答のあった場合はFAILUREとしてスポイルする
    if(response.data  && response.data.response === "failed") {
        console.log(response);

        next(Object.assign(action, {
                payload: {
                    ...response.data
                },
                meta: {
                    fetch: false,
                    status: Actions.statusEnum.FAILURE
                }
            })
        );
        if(response.data.code === 3601){
            //セッション破棄
            store.dispatch(
                Actions.data.failure(response.data, true)
            )

            store.dispatch(Actions.data.session(false));

        }else if (response.data.code === 3602) {
            //ログイン済状態でのログインはエラーと見なさない
        }else {
            //エラー汎用処理
            store.dispatch(
                Actions.data.failure(response.data, true)
            )
        }
    }else{
        next(Object.assign(action, {
                payload: {
                    ...response.data
                },
                meta: {
                    fetch: false,
                    status: Actions.statusEnum.SUCCESS
                }
            })
        );
    }
};




export const connection = store => next => action => {
    next(action);
    // POSTリクエスト
    if (action.type.match("^CONNECTION") && action.meta.fetch) {
        if (action.payload.method !== null && action.payload.method === "post") {
            let query = {};
            if (action.payload.hasOwnProperty("query")) {
                query = action.payload.query;
            }
            apiAccess.post(action.payload.api,action.payload.data)
                .then((response) => {
                    filteringFailedConnection(next,action,response,store);
                })
                .catch((error) => {
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        if(error.response.status === 412){
                            next(Object.assign(action, {
                                    payload: {
                                        ...error.response.data
                                    },
                                    meta: {
                                        fetch: false,
                                        status: Actions.statusEnum.SUCCESS
                                    }
                                })
                            );
                        }else{
                            next(Object.assign(action, {
                                    payload: {
                                        ...error.response.data
                                    },
                                    meta: {
                                        fetch: false,
                                        status: Actions.statusEnum.FAILURE
                                    }
                                })
                            );
                        }


                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        console.log(error.request);
                        console.log(error.message);

                        const errorMessage = {
                            message: "原因不明のエラーが発生しました"
                        }
                        store.dispatch(
                            Actions.data.failure(errorMessage, true)
                        )
                        next(Object.assign(action, {
                                payload: {
                                },
                                meta: {
                                    fetch: false,
                                    status: Actions.statusEnum.ERROR
                                }
                            })
                        );


                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                        const errorMessage= {
                            message: error.message.toString()
                        };
                        store.dispatch(
                            Actions.data.failure(errorMessage, true)
                        );

                        next(Object.assign(action, {
                                payload: {
                                },
                                meta: {
                                    fetch: false,
                                    status: Actions.statusEnum.ERROR
                                }
                            })
                        );
                    }
                });

        };
        // GETリクエスト
        if (action.payload.method !== null && action.payload.method === 'get') {
            let query = {};
            if (action.payload.hasOwnProperty("query")) {
                query = action.payload.query;
            }
            apiAccess.get(action.payload.api,action.payload.data)
                .then((response) => {

                    filteringFailedConnection(next,action,response,store);

                })
                .catch((error) => {
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        if(error.response.status === 412){
                            next(Object.assign(action, {
                                    payload: {
                                        ...error.response.data
                                    },
                                    meta: {
                                        fetch: false,
                                        status: Actions.statusEnum.SUCCESS
                                    }
                                })
                            );
                        }else{
                            next(Object.assign(action, {
                                    payload: {
                                        ...error.response.data
                                    },
                                    meta: {
                                        fetch: false,
                                        status: Actions.statusEnum.FAILURE
                                    }
                                })
                            );
                        }


                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        const errorMessage = {
                            message: "原因不明のエラーが発生しました"
                        }
                        store.dispatch(
                            Actions.data.failure(errorMessage, true)
                        )
                        next(Object.assign(action, {
                                payload: {
                                },
                                meta: {
                                    fetch: false,
                                    status: Actions.statusEnum.ERROR
                                }
                            })
                        );


                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                        const errorMessage= {
                            message: error.message.toString()
                        };
                        store.dispatch(
                            Actions.data.failure(errorMessage, true)
                        );

                        next(Object.assign(action, {
                                payload: {
                                },
                                meta: {
                                    fetch: false,
                                    status: Actions.statusEnum.ERROR
                                }
                            })
                        );
                    }
                })

        }
    }
};