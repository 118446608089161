import React, {useEffect, useState} from 'react';
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import CategoryEditDialog from "./CategoryEditDialog";
import IconButton from '@material-ui/core/IconButton';

import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CategoryDeleteDialog from "./CategoryDeleteDialog";
import Button from "@material-ui/core/Button";
import {Create} from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import {CopyToClipboard} from "react-copy-to-clipboard";
import Snackbar from "@material-ui/core/Snackbar";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 1200,
    },
    alert: {
        marginBottom: theme.spacing(2)
    },
    actionHeader: {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: theme.spacing(2),
    },
    margin: {
        marginRight: theme.spacing(1),
    },
    urlWrapper: {
        display: "flex",
        alignContent: "center"
    },
    urlSpan: {
        alignSelf: "center",
        color: "#999999",
        marginRight: theme.spacing(2)
    },
}));

export default function CategoryInterface() {
    const classes = useStyles();
    const categoryList = useSelector(state => state.categoryList);
    const categoryEditConnection = useSelector(state => state.categoryEditConnection);

    const [isOpenEditDialog, setOpenEditDialog] = useState(false);
    const [isOpenDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isEditMode, setEditMode] = useState(false);
    const [data, setData] = useState({});

    const [open, setOpen] = useState(false);


    return (
        <>

            <div className={classes.alert}>
                <Alert severity="info">
                    <AlertTitle>表示用URLについて</AlertTitle>
                    <span>
                        表示用URLをブラウザで開くことで、各カテゴリの表示状況を確認することができます。<br />
                        サイネージディスプレイへ表示するカテゴリを追加する場合はサイネージ管理システムへの設定値変更が必要となります。<br />
                        複数のカテゴリを順番に表示する場合は <b>https://(中略)/signage/1,2,3</b>のようにカテゴリNoをカンマ区切りで設定してください。
                    </span>
                </Alert>
            </div>
            <div className={classes.actionHeader}>
                <Button
                    variant="contained"
                    color={"primary"}
                    className={classes.button}
                    startIcon={<Create/>}
                    disableElevation
                    onClick={() => {
                        setEditMode(false);
                        setOpenEditDialog(true);
                    }}
                >
                    新しいカテゴリを作成
                </Button>
            </div>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>No</TableCell>
                            <TableCell>カテゴリ名</TableCell>
                            <TableCell>表示用URL</TableCell>
                            <TableCell width={80}> </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {categoryList.payload.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {row.CATEGORY_ID}
                                </TableCell>
                                <TableCell>{row.CATEGORY_NAME}</TableCell>
                                <TableCell>
                                    {
                                        (() => {
                                            const url = row.CATEGORY_URL;
                                            const result = url.match(/^https?:\/{2,}(.*?)(?:\/|\?|#|$)/)[1];
                                            return (
                                                <div className={classes.urlWrapper}>
                                                    <div className={classes.urlSpan}>
                                                        {`https://${result}/signage/${row.CATEGORY_ID}`}
                                                    </div>

                                                    <Tooltip title="クリップボードにコピー" aria-label="clipboard">
                                                        <CopyToClipboard
                                                            text={`https://${result}/signage/${row.CATEGORY_ID}`}
                                                            onCopy={() => {
                                                                setOpen(true)
                                                            }}>
                                                            <IconButton aria-label="delete" size={"small"}
                                                                        className={classes.iconButton}>
                                                                <FileCopyIcon/>
                                                            </IconButton>
                                                        </CopyToClipboard>
                                                    </Tooltip>

                                                    <Tooltip title="新しいウインドウで開く" aria-label="openBrowser">
                                                        <IconButton aria-label="delete" size={"small"}
                                                                    target={"_blank"}
                                                                    href={`https://${result}/signage/${row.CATEGORY_ID}`}
                                                                    className={classes.iconButton}>
                                                            <OpenInBrowserIcon/>
                                                        </IconButton>
                                                    </Tooltip>

                                                </div>
                                            )

                                        })()
                                    }
                                </TableCell>

                                <TableCell align="right">
                                    <Tooltip title="編集" aria-label="edit">
                                        <IconButton aria-label="delete" className={classes.margin}
                                                    size={"small"}
                                                    onClick={() => {
                                                        setData(row);
                                                        setEditMode(true);
                                                        setOpenEditDialog(true);
                                                    }
                                                    }>
                                            <EditIcon/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="削除" aria-label="delete">
                                        <IconButton aria-label="delete" className={classes.margin}
                                                    size={"small"}
                                                    onClick={() => {
                                                        setData(row);
                                                        setOpenDeleteDialog(true)
                                                    }}>
                                            <DeleteForeverIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Snackbar

                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={open} autoHideDuration={3000} onClose={() => {
                setOpen(false)
            }}>
                <Alert onClose={() => {
                    setOpen(false)
                }} severity="success">
                    URLをコピーしました
                </Alert>
            </Snackbar>


            <CategoryDeleteDialog data={data} isOpen={isOpenDeleteDialog}
                                  changeOpenState={(value) => setOpenDeleteDialog(value)}/>

            <CategoryEditDialog isEditMode={isEditMode} data={data} isOpen={isOpenEditDialog}
                                changeOpenState={(value) => setOpenEditDialog(value)}/>
        </>
    );
}
