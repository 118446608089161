import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import * as Actions from "../../flex/Actions";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import {makeStyles} from "@material-ui/styles";
import TableBody from "@material-ui/core/TableBody";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        width: "100%",
    },
}));

export default function TopicChoiceTemplateDialog(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [value,setValue] = useState("no_use_template");
    const [array, setArray] = useState([]);
    const templateList = useSelector(state => state.templateList);


    useEffect(()=> {
        dispatch(Actions.http.connection.templates.getTemplates())
    },[]);

    //template取得部の終了の際、利用するarrayのパラメータを変更
    useEffect (() => {
        setArray(templateList.payload);
    },[templateList])


    const handleClose = () => {
        props.changeOpenState(false)
    };

    const choiceTemplate = () => {
        if(value === "no_use_template"){
            props.setTemplate("");
        }else{
            const index = array.findIndex((row) => row.TEMPLATE_ID === value);
            props.setTemplate(array[index].TEMPLATE_BODY);
        }
    };


    return (
        <Dialog open={props.isOpen} onClose={handleClose} aria-labelledby="form-dialog-title"
                maxWidth={"md"}
                fullWidth={true}
        >
            <DialogTitle id="form-dialog-title">テンプレートの選択</DialogTitle>
            <DialogContent>
                <Alert severity="info">
                    <span>
                        あらかじめ作成された記事内容を編集して新しい記事を作成することができます。
                        イベント情報の告知や休講情報などの連絡など定型文を何度も使用して記事を作成する場合に便利です。
                    </span>
                </Alert>
                <form className={classes.container}>
                    <FormControl className={classes.formControl} variant="standard" size={"medium"} margin="normal" style={{marginTop:"30px",marginBottom:"40px"}}>
                        <Select
                            variant={"standard"}
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            input={<Input />} >
                            <MenuItem key={"default"} value="no_use_template">テンプレートを利用しない</MenuItem>
                            {array.map((row, index) => (
                                <MenuItem key={index} value={row.TEMPLATE_ID}>{row.TEMPLATE_TITLE}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    キャンセル
                </Button>
                <Button onClick={choiceTemplate} color="primary">
                    選択
                </Button>
            </DialogActions>
        </Dialog>
    );
}


TopicChoiceTemplateDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    changeOpenState: PropTypes.func.isRequired
};


TopicChoiceTemplateDialog.defaultProps = {
    isOpen: false,
    changeOpenState: () => console.warn("need changeOpenState func")
};