import {createActions, createAction, handleActions, combineActions} from 'redux-actions'
import * as endpoints from "../apis/endpoints";


export const statusEnum = {
    REQUEST: "REQUEST",
    LOADING: "LOADING",
    SUCCESS: "SUCCESS",
    FAILURE: "FAILURE",
    ERROR: "ERROR"
};

export const http = createActions({
    CONNECTION: {
        AUTHENTICATION: {
            LOGIN: [
                (data) => ({
                    method: "post",
                    api: endpoints.AUTH_ADMIN,
                    data
                }),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ],
            LOGOUT: [
                () => ({
                    method: "get",
                    api: endpoints.LOGOUT
                }),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ],
            CURRENT: [
                () => ({
                    method: "get",
                    api: endpoints.API_CURRENT
                }),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ]
        },
        CATEGORY : {
            GET_ALL_CATEGORY : [
                (data) => ({
                    method: "get",
                    api: endpoints.GET_ALL_CATEGORY,
                    data
                }),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ],
            CREATE_CATEGORY :[
                (data) => ({
                    method: "post",
                    api: endpoints.CREATE_CATEGORY,
                    data
                }),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ],
            EDIT_CATEGORY : [
                (data) => ({
                    method: "post",
                    api: endpoints.EDIT_CATEGORY,
                    data
                }),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ],
            DELETE_CATEGORY :[
                (data) => ({
                    method: "post",
                    api: endpoints.DELETE_CATEGORY,
                    data
                }),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ]
        },
        TEMPLATES : {
           GET_TEMPLATES: [
               (data) => ({
                   method: "post",
                   api: endpoints.GET_TEMPLATES,
                   data
               }),
               () => ({fetch: true, status: statusEnum.REQUEST})
           ],
            CREATE_TEMPLATE: [
                (data) => ({
                    method: "post",
                    api: endpoints.CREATE_TEMPLATE,
                    data
                }),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ],
            EDIT_TEMPLATE: [
                (data) => ({
                    method: "post",
                    api: endpoints.EDIT_TEMPLATE,
                    data
                }),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ],
            DELETE_TEMPLATE: [
                (data) => ({
                    method: "post",
                    api: endpoints.DELETE_TEMPLATE,
                    data
                }),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ]
        },
        TOPICS: {
            GET_TOPICS: [
                (data) => ({
                    method: "post",
                    api: endpoints.GET_TOPICS,
                    data
                }),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ],
            CREATE_TOPIC: [
                (data) => ({
                    method: "post",
                    api: endpoints.CREATE_TOPIC,
                    data
                }),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ],
            EDIT_TOPIC: [
                (data) => ({
                    method: "post",
                    api: endpoints.EDIT_TOPIC,
                    data
                }),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ],
            DELETE_TOPIC: [
                (data) => ({
                    method: "post",
                    api: endpoints.DELETE_TOPIC,
                    data
                }),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ]
        },
        UTILS : {
            GET_S3_INFO: [
                (data) => ({
                    method: "get",
                    api: endpoints.GET_S3_INFO ,
                    data
                }),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ]
        },
        SETTINGS: {
            POST_SETTINGS: [
                (data) => ({
                    method: "post",
                    api: endpoints.EDIT_KS_SETTINGS,
                    data
                }),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ],
            GET_SETTINGS: [
                () => ({
                    method: "get",
                    api: endpoints.GET_KS_SETTINGS
                }),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ],
        }
    }
});

export const data = createActions({
    USER: data => (data),
    SESSION: (is_authenticated) => ({
        is_checked : true,
        is_authenticated
    }),
    CATEGORY_LIST : data => (data),
    TOPIC_LIST: data => (data),
    TEMPLATE_LIST: data=> (data),
    S3_Info: data=> (data),
    SETTINGS: data=> (data),
    FAILURE: [
        (data) => data,
        (data,bool) => ({isShow: bool})
    ],
    SUCCESS: [
        (data) => data,
        (data,bool) => ({isShow: bool})
    ]
});
