import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import * as Actions from "../../flex/Actions";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import {makeStyles} from "@material-ui/styles";
import InputLabel from "@material-ui/core/InputLabel";
import TopicFroalaEditor from "../topics/TopicFroalaEditor";
import ProgressButton from "../../utils/ProgressButton";


const useStyles = makeStyles(theme => ({
    formControl: {
        marginBottom: theme.spacing(3),
    },
    flex: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    flexColumn: {
        display: "flex",
        flexDirection: "column"
    },
    flexSpacer: {
        width: "20px",
        height: "auto"
    },
    labelSpacer: {
        height: "20px",
        width: "100%"
    }
}));

export default function TemplateEditDialog(props) {
    const dispatch = useDispatch();
    const classes = useStyles();

    const templateList = useSelector(state => state.templateList);
    const templateEditConnection = useSelector(state => state.templateEditConnection);
    //記事名
    const [templateTitle, setTemplateTitle] = useState("");
    //記事カテゴリ
    const [templateDescription, setTemplateDescription] = useState("");
    //本文
    const [templateBody, setTemplateBody] = useState("");

    const [loading,setLoading] = useState(false);

    useEffect(() => {
        if (props.isEditMode) {
            setTemplateTitle(props.data.TEMPLATE_TITLE);
            setTemplateBody(props.data.TEMPLATE_BODY);
            setTemplateDescription(props.data.TEMPLATE_DESCRIPTION);
        } else {
            setTemplateTitle("");
            setTemplateBody("");
            setTemplateDescription("");
        }
    }, [props])

    useEffect(() => {
        if (templateEditConnection.meta.status === Actions.statusEnum.SUCCESS && templateEditConnection.payload.response === "success") {
            handleClose()
        }
        setLoading(templateEditConnection.meta.fetch);
    }, [templateEditConnection]);

    const handleClose = () => {
        props.changeOpenState(false)
    };


    const editTemplate = () => {
        //記事の編集
        const postData = {
            TEMPLATE_ID: props.data.TEMPLATE_ID,
            TEMPLATE_TITLE: templateTitle,
            TEMPLATE_DESCRIPTION: templateDescription,
            TEMPLATE_BODY: templateBody
        };
        dispatch(Actions.http.connection.templates.editTemplate(postData));
    };
    const createTemplate = () => {
        //記事の作成
        const postData = {
            //todo topicDataの編集
            TEMPLATE_TITLE: templateTitle,
            TEMPLATE_DESCRIPTION: templateDescription,
            TEMPLATE_BODY: templateBody
        };
        dispatch(Actions.http.connection.templates.createTemplate(postData));
    };

    const handleSubmit = () => {
        setLoading(true);
        if (props.isEditMode) {
            editTemplate();
        } else {
            createTemplate();
        }
    };

    return (
        <Dialog open={props.isOpen} onClose={handleClose} aria-labelledby="form-dialog-title"
                fullScreen={true}
        >
            <form onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
            }}>
                <DialogTitle id="form-dialog-title">テンプレートの編集</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth={true} component="fieldset" className={classes.formControl}>
                        <TextField
                            InputLabelProps={{shrink: true}}
                            autoFocus
                            required
                            margin="dense"
                            id="template_title"
                            label="テンプレート名"
                            fullWidth
                            value={templateTitle}
                            onChange={(event) => {
                                setTemplateTitle(event.target.value)
                            }}
                        />
                    </FormControl>
                    <FormControl fullWidth={true} component="fieldset" className={classes.formControl}>
                        <TextField
                            InputLabelProps={{shrink: true}}
                            autoFocus
                            margin="dense"
                            id="template_decription"
                            label="テンプレート説明"
                            fullWidth
                            value={templateDescription}
                            onChange={(event) => {
                                setTemplateDescription(event.target.value)
                            }}
                        />
                    </FormControl>
                    <FormControl fullWidth={true} component="fieldset" className={classes.formControl}>
                        <InputLabel shrink>
                            本文
                        </InputLabel>
                        <div className={classes.flexColumn}>
                            <div className={classes.labelSpacer}/>
                            <TopicFroalaEditor
                                value={templateBody}
                                onChange={(value) => {
                                    setTemplateBody(value);
                                }
                                }/>
                        </div>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        キャンセル
                    </Button>
                    <ProgressButton type={"submit"} color="primary" loading={loading}>
                        {props.isEditMode ? "変更" : "作成"}
                    </ProgressButton>
                </DialogActions>
            </form>
        </Dialog>
    );
}


TemplateEditDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    changeOpenState: PropTypes.func.isRequired
};


TemplateEditDialog.defaultProps = {
    isOpen: false,
    changeOpenState: () => console.warn("need changeOpenState func")
};