import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Dispatch } from 'redux';
import * as Reducers from "../flex/Reducers";
import * as Actions from "../flex/Actions";
import * as endpoints from "../apis/endpoints";
import { apiAccess } from "../apis/AxiosWrapper"
import {
    useHistory,
} from "react-router-dom";




export default function S3InfoEffectComponent() {
    const s3InfoConnection = useSelector(state => state.s3InfoConnection)
    const dispatch = useDispatch();

    useEffect(() => {
        if(s3InfoConnection.meta.status === Actions.statusEnum.SUCCESS && s3InfoConnection.payload.response === "success"){
            dispatch(Actions.data.s3Info(s3InfoConnection.payload.data));
        }else{
            if(s3InfoConnection.meta.status === Actions.statusEnum.FAILURE || s3InfoConnection.meta.status === Actions.statusEnum.ERROR){

            }
        }
    }, [s3InfoConnection]);

    return null
}