import React, {useEffect, useState} from 'react';
import ContentHeader from "../ContentHeader";
import {useSelector} from "react-redux";
import {
    useParams
} from "react-router-dom";
import TemplatesEffectComponent from "../../EffectComponent/TemplatesEffectComponent";
import TemplatesInterface from "./TemplatesInterface";

export default function Templates() {
    const [title,setTitle] = useState("");

    useEffect(() => {

    },[]);

    return (
        <>
            <ContentHeader title={"テンプレート管理"} breadCrumbItems={[
                {title:`テンプレート管理`,icon:"notification",path:""}
            ]}/>
            <TemplatesInterface />
            <TemplatesEffectComponent />
        </>
    );

}
