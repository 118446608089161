import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {useDispatch} from "react-redux";
import Button from "@material-ui/core/Button";
import * as Actions from "../../flex/Actions";
import * as Helper from "../../utils/Helper";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const useStyles = makeStyles(theme => ({
    headerBox: {
        padding: "24px"
    },
    logo: {
        height: "80px",
        width: "100%",
        backgroundSize: "contain",
        backgroundImage: `url("${process.env.PUBLIC_URL}/skin/${Helper.generateTenantKey(window.location.host).key}/logo.png")`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"
    },
    title: {
        marginBottom:"14px",
    }
}));


export default function MenuHeader() {
    const classes = useStyles();
    const dispatch = useDispatch();

    return (
        <div className={classes.headerBox}>
            <div className={classes.logo} />
            <div className={classes.title}>サイネージ マネージャー</div>
            <Button variant="contained" disableElevation startIcon={<ExitToAppIcon />} color="secondary" fullWidth={true} onClick={()=>dispatch(Actions.http.connection.authentication.logout())}>
                サインアウト
            </Button>
        </div>
    )
}