import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import './scss/index.scss';
import App from './App';
import {applyMiddleware, combineReducers, createStore} from "redux";
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker';
import * as Reducers from "./flex/Reducers";
import {connection} from "./flex/middleware";


const rootReducer = combineReducers({
    ...Reducers
});
const store = createStore(rootReducer,
    applyMiddleware(
        connection
    )
);

process.env.NODE_ENV !== "development" && (console.log = () => {});

ReactDOM.render(
    <Provider  store={store}>
        <App />
    </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
