
export const API_HOST = process.env.REACT_APP_API_HOST;
export const API_SUB_DIRECTORY = process.env.REACT_APP_API_SUB_DIRECTORY;
export const API_VERSION = process.env.REACT_APP_API_VERSION;

export const API_LOGIN =`/${API_SUB_DIRECTORY}/${API_VERSION}/wall/login`;
export const API_LOGOUT =`/${API_SUB_DIRECTORY}/${API_VERSION}/wall/logout`;

export const API_GET_ALL_CATEGORY =`/${API_SUB_DIRECTORY}/${API_VERSION}/wall/get-all-category`;


export const AUTH_ADMIN =  `/${API_SUB_DIRECTORY}/login`;
export const API_CURRENT = `/${API_SUB_DIRECTORY}/get-current-user`;

export const GET_ALL_CATEGORY = `/${API_SUB_DIRECTORY}/get-all-category`
export const GET_TOPICS = `/${API_SUB_DIRECTORY}/get-topics`
export const CREATE_TOPIC = `/${API_SUB_DIRECTORY}/create-topic`
export const EDIT_TOPIC = `/${API_SUB_DIRECTORY}/edit-topic`
export const DELETE_TOPIC = `/${API_SUB_DIRECTORY}/delete-topic`
export const FETCH_TOPIC_ORDER = `/${API_SUB_DIRECTORY}/fetch-topic-order`
export const CREATE_CATEGORY = `/${API_SUB_DIRECTORY}/create-category`
export const EDIT_CATEGORY = `/${API_SUB_DIRECTORY}/edit-category`
export const DELETE_CATEGORY = `/${API_SUB_DIRECTORY}/delete-category`
export const LOGOUT = `/${API_SUB_DIRECTORY}/logout`


export const GET_TEMPLATES = `/${API_SUB_DIRECTORY}/get-topic-templates`
export const CREATE_TEMPLATE = `/${API_SUB_DIRECTORY}/create-topic-template`
export const EDIT_TEMPLATE = `/${API_SUB_DIRECTORY}/edit-topic-template`
export const DELETE_TEMPLATE = `/${API_SUB_DIRECTORY}/delete-topic-template`

export const GET_S3_INFO = `/${API_SUB_DIRECTORY}/get-sss-info`;


export const GET_KS_SETTINGS = `/${API_SUB_DIRECTORY}/get-ks-settings`
export const EDIT_KS_SETTINGS = `/${API_SUB_DIRECTORY}/edit-ks-settings`