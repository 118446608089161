import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import * as Actions from "../../flex/Actions";
import ProgressButton from "../../utils/ProgressButton";



export default function CategoryEditDialog(props) {

    const dispatch = useDispatch();
    const categoryEditConnection = useSelector(state => state.categoryEditConnection)
    const [categoryInput, setCategoryInput] = useState("");
    const [loading,setLoading] = useState(false);

    useEffect(() => {
        if (props.isEditMode) {
            setCategoryInput(props.data.CATEGORY_NAME)
        } else {
            setCategoryInput("")
        }
    }, [props.isEditMode,props.data]);

    useEffect(() => {
        if(categoryEditConnection.meta.status === Actions.statusEnum.SUCCESS && categoryEditConnection.payload.response === "success"){
            handleClose()
        }
        setLoading(categoryEditConnection.meta.fetch)
    }, [categoryEditConnection])

    const changeInput = (event) => {
        setCategoryInput(event.target.value)
    };

    const createCategory = () => {
        const postData = {
            CATEGORY_NAME: categoryInput
        };
        dispatch(Actions.http.connection.category.createCategory(postData));
    };

    const editCategory = () => {
        const postData = {
            CATEGORY_ID: props.data.CATEGORY_ID,
            CATEGORY_NAME: categoryInput
        };
        dispatch(Actions.http.connection.category.editCategory(postData));
    };

    const handleClose = () => {
        props.changeOpenState(false)
    };

    const handleSubmit = () => {
        setLoading(true);
        if (props.isEditMode) {
            editCategory();
        } else {
            createCategory();
        }
    };

    return (
        <>
            <Dialog open={props.isOpen} onClose={handleClose} aria-labelledby="form-dialog-title"
                    maxWidth={"sm"}
                    fullWidth={true}>
                <DialogTitle id="form-dialog-title">{props.isEditMode ? "カテゴリの編集" : "カテゴリの新規作成"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        カテゴリのタイトルを{props.isEditMode ? "編集" : "入力"}してください
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="category"
                        label="カテゴリ名"
                        fullWidth
                        value={categoryInput}
                        onChange={changeInput}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        キャンセル
                    </Button>
                    <ProgressButton onClick={handleSubmit} color="primary" loading={loading}>
                        {props.isEditMode ? "変更" : "作成"}
                    </ProgressButton>
                </DialogActions>

            </Dialog>
        </>
    );
}


CategoryEditDialog.propTypes = {
    isEditMode: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool.isRequired,
    changeOpenState: PropTypes.func.isRequired
};


CategoryEditDialog.defaultProps = {
    isEditMode: false,
    isOpen: false,
    changeOpenState: () => console.warn("need changeOpenState func")
};