import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as Actions from "../flex/Actions";
import {useParams} from "react-router-dom";


export default function TopicsEffectComponent(props) {
    const dispatch = useDispatch();
    const topicGetConnection = useSelector(state => state.topicGetConnection);
    const topicEditConnection = useSelector(state => state.topicEditConnection);
    const topicDeleteConnection = useSelector( state => state.topicDeleteConnection);

    let {id} = useParams();

    useEffect(() => {
        if(topicGetConnection.meta.status === Actions.statusEnum.SUCCESS && topicGetConnection.payload.response === "success"){
            console.log(topicGetConnection)
            dispatch(Actions.data.topicList(topicGetConnection.payload.data));
        }
    }, [topicGetConnection]);


    //カテゴリの編集削除を行った際はリロードかける
    useEffect( ()=> {
        if(topicDeleteConnection.meta.status === Actions.statusEnum.SUCCESS && topicDeleteConnection.payload.response === "success"){
            const params = {CATEGORIES: [`${id}`]}
            dispatch(Actions.http.connection.topics.getTopics(params));
        }
        if(topicEditConnection.meta.status === Actions.statusEnum.SUCCESS && topicEditConnection.payload.response === "success"){
            const params = {CATEGORIES: [`${id}`]}
            dispatch(Actions.http.connection.topics.getTopics(params));
        }
    },[topicDeleteConnection,topicEditConnection])
    return null
}