import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as Actions from "../flex/Actions";
import {useParams} from "react-router-dom";


export default function TemplatesEffectComponent(props) {
    const dispatch = useDispatch();
    const templateGetConnection = useSelector(state => state.templateGetConnection);
    const templateEditConnection = useSelector(state => state.templateEditConnection);
    const templateDeleteConnection = useSelector( state => state.templateDeleteConnection);

    let {id} = useParams();

    useEffect(() => {
        if(templateGetConnection.meta.status === Actions.statusEnum.SUCCESS && templateGetConnection.payload.response === "success"){
            console.log(templateGetConnection)
            dispatch(Actions.data.templateList(templateGetConnection.payload.data));
        }
    }, [templateGetConnection]);


    //カテゴリの編集削除を行った際はリロードかける
    useEffect( ()=> {
        if(templateDeleteConnection.meta.status === Actions.statusEnum.SUCCESS && templateDeleteConnection.payload.response === "success"){
            dispatch(Actions.http.connection.templates.getTemplates());
        }
        if(templateEditConnection.meta.status === Actions.statusEnum.SUCCESS && templateEditConnection.payload.response === "success"){
            dispatch(Actions.http.connection.templates.getTemplates());
        }
    },[templateDeleteConnection,templateEditConnection])
    return null
}