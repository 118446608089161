import * as Actions from "./Actions";
import {combineActions, handleAction, handleActions} from "redux-actions";

const defaultState = ({
    payload: {},
    meta: {
        fetch: false,
        status: ""
    }
});

const defaultReducer = {
    next: (state, action) => {
        return {...action}
    },
    throw: (state, action) => {
        return (Object.assign(action, {
            meta: {
                fetch: false,
                status: Actions.statusEnum.FAILURE
            }
        }))
    }
};

export const currentConnection = handleAction(
    Actions.http.connection.authentication.current,
    defaultReducer,
    defaultState
);


export const loginConnection = handleAction(
    Actions.http.connection.authentication.login,
    defaultReducer,
    defaultState
);
export const logoutConnection = handleAction(
    Actions.http.connection.authentication.logout,
    defaultReducer,
    defaultState
);
export const categoryConnection = handleAction(
    Actions.http.connection.category.getAllCategory,
    defaultReducer,
    defaultState
);
export const categoryEditConnection = handleAction(
    combineActions(
        Actions.http.connection.category.editCategory,
        Actions.http.connection.category.createCategory
    ),
    defaultReducer,
    defaultState
);
export const categoryDeleteConnection = handleAction(
    Actions.http.connection.category.deleteCategory,
    defaultReducer,
    defaultState
);
export const topicGetConnection = handleAction(
    Actions.http.connection.topics.getTopics,
    defaultReducer,
    defaultState
);
export const topicEditConnection = handleAction(
    combineActions(
        Actions.http.connection.topics.editTopic,
        Actions.http.connection.topics.createTopic
    ),
    defaultReducer,
    defaultState
);
export const topicDeleteConnection = handleAction(
    Actions.http.connection.topics.deleteTopic,
    defaultReducer,
    defaultState
);


export const templateGetConnection = handleAction(
    Actions.http.connection.templates.getTemplates,
    defaultReducer,
    defaultState
);
export const templateEditConnection = handleAction(
    combineActions(
        Actions.http.connection.templates.editTemplate,
        Actions.http.connection.templates.createTemplate
    ),
    defaultReducer,
    defaultState
);
export const templateDeleteConnection = handleAction(
    Actions.http.connection.templates.deleteTemplate,
    defaultReducer,
    defaultState
);




export const s3InfoConnection = handleAction(
    Actions.http.connection.utils.getS3Info,
    defaultReducer,
    defaultState
);

export const settingsConnection = handleAction(
    combineActions(
        Actions.http.connection.settings.getSettings,
        Actions.http.connection.settings.postSettings,
    ),
    defaultReducer,
    defaultState
);




const defaultAuthState = ({
    payload: {
        is_checked: false,
        ia_authenticated: false
    }
});
const defaultArrayState = ({
    payload: []
});
const defaultObjectState= ({
    payload: {}
})
export const session = handleAction(
    Actions.data.session,
    (state, action) => {
        return action
    },
    defaultAuthState
);
export const user = handleAction(
    Actions.data.user,
    (state, action) => {
        return action
    },
    defaultObjectState
);
export const categoryList = handleAction(
    Actions.data.categoryList,
    (state, action) => {
        return action
    },
    defaultArrayState
);
export const topicList = handleAction(
    Actions.data.topicList,
    (state, action) => {
        return action
    },
    defaultObjectState
);
export const templateList = handleAction(
    Actions.data.templateList,
    (state, action) => {
        return action
    },
    defaultArrayState
);

export const s3Info = handleAction(
    Actions.data.s3Info,
    (state,action) => {
        return action
    },
    defaultObjectState
);

export const settings = handleAction(
    Actions.data.settings,
    (state,action) => {
        return action
    },
    defaultObjectState
);




//Errorハンドル用
const defaultFailureObject = ({
    payload: {
        api_version: "error",
        api_type: "FRONTEND",
        response: "failed",
        code: 500,
        message: "",
    },
    meta: {
        isShow: false
    }
});
export const failure = handleAction(
    Actions.data.failure,
    (state, action) => {
        return action
    },
    defaultFailureObject
);

//Successハンドル用
const defaultSuccessObject = ({
    payload: {
        message: "",
    },
    meta: {
        isShow: false
    }
});
export const success = handleAction(
    Actions.data.success,
    (state, action) => {
        return action
    },
    defaultSuccessObject
);