import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Dispatch } from 'redux';
import * as Reducers from "../flex/Reducers";
import * as Actions from "../flex/Actions";
import * as endpoints from "../apis/endpoints";
import { apiAccess } from "../apis/AxiosWrapper"
import {
    useHistory,
} from "react-router-dom";
import {currentConnection} from "../flex/Reducers";

export default function AuthEffectComponent() {
    const loginConnection = useSelector(state => state.loginConnection)
    const logoutConnection = useSelector(state => state.logoutConnection)
    const currentConnection = useSelector(state => state.currentConnection);
    const dispatch = useDispatch();

    useEffect(() => {
        if(loginConnection.meta.status === Actions.statusEnum.SUCCESS && loginConnection.payload.response === "success"){
            dispatch(Actions.data.session(true));
            dispatch(Actions.data.user(loginConnection.payload.data))
        }else{
            //ログイン済みの場合はtrue
            if(loginConnection.payload.code === 3602) {
                dispatch(Actions.data.session(true));
            }else
            //エラーのときのみ処理する
            if(loginConnection.meta.status === Actions.statusEnum.FAILURE || loginConnection.meta.status === Actions.statusEnum.ERROR){
                dispatch(Actions.data.session(false));
            }
        }
    }, [loginConnection]);

    //セッション情報を朱徳するためのもの
    useEffect(()=>{
        if(currentConnection.meta.status === Actions.statusEnum.SUCCESS && currentConnection.payload.response === "success"){
            dispatch(Actions.data.session(true));
            dispatch(Actions.data.user(currentConnection.payload.data))
        }else{
            if(currentConnection.meta.status === Actions.statusEnum.FAILURE || currentConnection.meta.status === Actions.statusEnum.ERROR){
                dispatch(Actions.data.session(false));
            }
        }
    },[currentConnection])


    useEffect(() => {
        if(logoutConnection.meta.status === Actions.statusEnum.SUCCESS){
            dispatch(Actions.data.session(false));
        }
        if(logoutConnection.meta.status === Actions.statusEnum.FAILURE || logoutConnection.meta.status === Actions.statusEnum.ERROR){
            dispatch(Actions.data.session(true));
        }
    },[logoutConnection])


    return null
}