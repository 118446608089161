import React, {useEffect, useState} from 'react';
import ContentHeader from "../ContentHeader";
import {useSelector} from "react-redux";
import {
    useParams
} from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

export default function SuccessNotice() {
    const success = useSelector(state => state.success);
    const [open,setOpen] = useState(false)

    useEffect(() => {
        setOpen(success.meta.isShow);
    },[success]);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        success.meta.isShow ?
            <Snackbar
                anchorOrigin={{horizontal: "center",vertical: "top"}}
                open={open} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    {success.payload.message}
                </Alert>
            </Snackbar>
            :
            null
    );
};
