import React, {useEffect, useState} from 'react';
import ContentHeader from "../ContentHeader";
import {useSelector} from "react-redux";
import {
    useParams
} from "react-router-dom";
import SettingsInterface from "./SettingsInterface";
import SettingsEffectComponent from "../../EffectComponent/SettingsEffectComponent";

export default function Settings() {
    return (
        <>
            <ContentHeader title="設定" breadCrumbItems={[
                {title:"設定",icon:"settings",path:"/settings"}
            ]}/>
            <SettingsEffectComponent />
            <SettingsInterface/>
        </>
    );

}
