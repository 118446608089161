import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as Actions from "../flex/Actions";


export default function SettingsEffectComponent() {
    const settingsConnection = useSelector(state => state.settingsConnection)
    const categoryDeleteConnection = useSelector(state => state.categoryDeleteConnection)
    const categoryEditConnection = useSelector(state => state.categoryEditConnection)
    const dispatch = useDispatch();

    useEffect(() => {
        if(settingsConnection.meta.status === Actions.statusEnum.SUCCESS && settingsConnection.payload.response === "success"){
            //setting書き換え時のサクセス用
            if(settingsConnection.type === Actions.http.connection.settings.postSettings().type){
                dispatch(
                    Actions.data.success({message: "設定を保存しました"}, true)
                );
            }
            dispatch(Actions.data.settings(settingsConnection.payload.data));
        }
    }, [settingsConnection]);

    return null
}