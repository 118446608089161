import React, {useEffect, useState} from 'react';
import ContentHeader from "../ContentHeader";
import {useDispatch, useSelector} from "react-redux";
import {
    useParams
} from "react-router-dom";
import Button from "@material-ui/core/Button";
import {Create} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import * as Actions from "../../flex/Actions";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import SuccessNotice from "../success/SuccessNotice";

import DoneIcon from '@material-ui/icons/Done';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 1200,
    },
    main: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(4),
    },
    label: {
        marginBottom: 40
    },
    flex: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        maxWidth: "1500px"
    },
    sliderWrapper: {
        flexGrow: 1,
        flexBasis: "50%",
        margin: theme.spacing(4)
    },
    sliderWrapperButton: {
        textAlign:"right",
    },
    resetButton: {
        marginRight: theme.spacing(2)
    }
}));

function valueLabelFormatPixel(value) {
    return `${value}%`;
}

function valueLabelFormatSeconds(value) {
    return `${value}秒`;
}

export default function SettingsInterface() {
    const classes = useStyles();

    const settings = useSelector(state => state.settings);
    const dispatch = useDispatch();
    const [scrollStartTime, setScrollStartTime] = useState(300);
    const [scrollEndTime, setScrollEndTime] = useState(1);
    const [scrollSpeed, setScrollSpeed] = useState(1);
    const [noScrollEndTime, setNoScrollEndTime] = useState(1);
    const [noTopicEndTime, setNoTopicEndTime] = useState(1);
    const [isOpenSuccess, setOpenSuccess] = useState(false);
    const [resetAlert,setResetAlert] = useState(false);

    useEffect(() => {
        dispatch(Actions.http.connection.settings.getSettings())
    }, [])


    useEffect(() => {
        console.log(settings);
        /*
            SCROLL_START_TIME: "1000"
            SCROLL_END_TIME: "1000"
            SCROLL_SPEED: "15"
            NO_SCROLL_END_TIME: "10000"
            NO_TOPIC_END_TIME: "10000"
         */
        if (Object.keys(settings.payload).length !== 0) {
            setScrollStartTime(Number(settings.payload.SCROLL_START_TIME) / 1000);
            setScrollEndTime(Number(settings.payload.SCROLL_END_TIME) / 1000);
            setScrollSpeed(101 - Number(settings.payload.SCROLL_SPEED));
            setNoScrollEndTime(Number(settings.payload.NO_SCROLL_END_TIME) / 1000);
            setNoTopicEndTime(Number(settings.payload.NO_TOPIC_END_TIME) / 1000);
        }
    }, [settings])

    const handleSubmit = () => {
        let postData = {
            SCROLL_START_TIME: scrollStartTime * 1000,
            SCROLL_END_TIME: scrollEndTime * 1000,
            SCROLL_SPEED: 101 - scrollSpeed,
            NO_SCROLL_END_TIME: noScrollEndTime * 1000,
            NO_TOPIC_END_TIME: noTopicEndTime * 1000,
        };
        dispatch(Actions.http.connection.settings.postSettings(postData));
    };

    const handleReset = () => {
        let postData={
            SCROLL_START_TIME: "10000",
            SCROLL_END_TIME: "10000",
            SCROLL_SPEED: "16",
            NO_SCROLL_END_TIME: "10000",
            NO_TOPIC_END_TIME: "10000"
        };
        dispatch(Actions.http.connection.settings.postSettings(postData));
    };



    return (
        <>
            <Alert severity="info">
                <AlertTitle>各種設定について</AlertTitle>
                <span>
                    全てのカテゴリの記事に反映されます。
                </span>
            </Alert>
            <Paper className={classes.main}>
                <Typography variant="h5" component="h2" gutterBottom>表示時間・スピードの調整</Typography>
                <div className={classes.flex}>
                    <div className={classes.sliderWrapper}>
                        <Typography variant="subtitle1" id="discrete-slider" gutterBottom className={classes.label}>
                            記事が表示されてから<br />スクロールを開始するまでの待ち時間
                        </Typography>
                        <Slider
                            value={scrollStartTime}
                            onChange={(e, v) => setScrollStartTime(v)}
                            valueLabelDisplay="on"
                            valueLabelFormat={valueLabelFormatSeconds}
                            step={1}
                            marks={[{value: 1, label: "１秒"}, {value: 60, label: "60秒"}]}

                            min={1}
                            max={60}
                        />
                    </div>

                    <div className={classes.sliderWrapper}>
                        <Typography variant="subtitle1" id="discrete-slider" gutterBottom className={classes.label}>
                            記事が最後まで表示されてから<br />次の記事に切り替わるまでの待ち時間
                        </Typography>
                        <Slider
                            value={scrollEndTime}
                            onChange={(e, v) => setScrollEndTime(v)}
                            valueLabelFormat={valueLabelFormatSeconds}
                            aria-labelledby="discrete-slider"
                            valueLabelDisplay="on"
                            step={1}
                            marks={[{value: 1, label: "１秒"}, {value: 60, label: "60秒"}]}

                            min={1}
                            max={60}
                        />
                    </div>
                </div>
                <div className={classes.flex}>
                    <div className={classes.sliderWrapper}>
                        <Typography variant="subtitle1" id="discrete-slider" gutterBottom className={classes.label}>
                            記事のスクロール速度
                        </Typography>
                        <Slider
                            value={scrollSpeed}
                            onChange={(e, v) => setScrollSpeed(v)}
                            valueLabelFormat={valueLabelFormatPixel}
                            aria-labelledby="discrete-slider"
                            valueLabelDisplay="on"
                            step={1}
                            marks={[{value: 1, label: "遅い"}, {value: 100, label: "早い"}]}
                            min={1}
                            max={100}
                        />
                    </div>
                </div>
                <div className={classes.flex}>
                    <div className={classes.sliderWrapper}>
                        <Typography variant="subtitle1" id="discrete-slider" gutterBottom className={classes.label}>
                            スクロールせずに全体が表示できる記事の表示時間<br />
                        </Typography>
                        <Slider
                            onChange={(e, v) => setNoScrollEndTime(v)}
                            value={noScrollEndTime}
                            valueLabelDisplay="on"
                            valueLabelFormat={valueLabelFormatSeconds}
                            aria-labelledby="discrete-slider"
                            step={1}
                            marks={[{value: 1, label: "１秒"}, {value: 60, label: "60秒"}]}
                            min={1}
                            max={60}
                        />
                    </div>
                </div>
                <div className={classes.flex}>
                    <div className={classes.sliderWrapper}>
                        <Typography variant="subtitle1" id="discrete-slider" gutterBottom className={classes.label}>
                            カテゴリ内の記事が無いときに、次のカテゴリに切り替わるまでの待ち時間
                        </Typography>
                        <Slider
                            defaultValue={0}
                            onChange={(e, v) => {
                                setNoTopicEndTime(v)
                            }}
                            valueLabelDisplay="on"
                            value={noTopicEndTime}
                            valueLabelFormat={valueLabelFormatSeconds}
                            aria-labelledby="discrete-slider"
                            step={1}
                            marks={[{value: 1, label: "１秒"}, {value: 60, label: "60秒"}]}
                            min={1}
                            max={60}
                        />
                    </div>
                </div>

                <div className={classes.sliderWrapperButton}>

                    <Button className={classes.resetButton} color={"secondary"} variant={"outlined"} size={"large"} onClick={()=>setResetAlert(true)}>リセット</Button>

                    <Button color={"primary"} variant={"contained"} size={"large"} startIcon={<DoneIcon/>}
                            onClick={handleSubmit}>保存する</Button>
                </div>
            </Paper>

            <Dialog
                open={resetAlert}
                onClose={()=>setResetAlert(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">設定パラメータの初期化</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        設定の値を初期化します。
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setResetAlert(false)} color="primary">
                        キャンセル
                    </Button>
                    <Button onClick={()=>{setResetAlert(false);handleReset()}} color="primary" autoFocus>
                        初期化
                    </Button>
                </DialogActions>
            </Dialog>


            <SuccessNotice/>

        </>
    );

}
