
export const generateTenantKey = (host) => {
    switch(window.location.host){
        case "ks-admin.toyo.ist-reserve.space":
            //東洋
            return {key:"toyo" , name:"東洋大学"};
        case "ks-admin.oau.ist-reserve.space":
            //大阪青山
            return {key:"oau" , name:"大阪青山大学"};
        default:
        case "localhost:3000":
            //それ以外の場合はdefaultとして処理
            return {key:"default" , name: "開発"};
    }
};


//FilesからBaset64へのConvertを行うPromise関数
export const convertBase64FromFiles = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = (error) => {
        reject(error);
    }
    reader.onload = () => {
        resolve(reader.result);
    };
    reader.readAsDataURL(file);
});


export const TopicPatrolledEnum = {
    NOTYET: "NOTYET",
    ACCEPTED: "ACCEPTED",
    REJECTED: "REJECTED",
    AUTO_ACCEPTED: "AUTO_ACCEPTED"
};