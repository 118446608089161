import React, {useEffect, useState} from 'react';
import ContentHeader from "../ContentHeader";
import TopicsInterface from "./TopicsInterface";
import {useSelector} from "react-redux";
import {
    useParams
} from "react-router-dom";
import TopicsEffectComponent from "../../EffectComponent/TopicsEffectComponent";
import TemplatesEffectComponent from "../../EffectComponent/TemplatesEffectComponent";

export default function Topics() {
    const categoryList = useSelector(state => state.categoryList);
    let { id } = useParams();
    const [title,setTitle] = useState("");

    useEffect(() => {
        console.log(id);
        const VALUE = categoryList.payload.find((value) => {
            return value.CATEGORY_ID === id;
        });
        setTitle(VALUE? VALUE.CATEGORY_NAME : "");
    },[id,categoryList]);


    return (
        <>
            <ContentHeader title={title} breadCrumbItems={[
                {title:`記事管理(${title})`,icon:"notification",path:""}
            ]}/>
            <TopicsEffectComponent />
            <TemplatesEffectComponent />
            <TopicsInterface />

        </>
    );

}
