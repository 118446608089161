import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import * as Actions from "../../flex/Actions";

export default function CategoryDeleteDialog(props) {
    const dispatch = useDispatch();

    const categoryDeleteConnection = useSelector(state => state.categoryDeleteConnection)

    useEffect(() => {
        console.log("変化")
        if (props.isEditMode) {

        } else {

        }
    }, [props])

    useEffect(() => {
        if(categoryDeleteConnection.meta.status === Actions.statusEnum.SUCCESS && categoryDeleteConnection.payload.response === "success"){
            handleClose()
        }
    }, [categoryDeleteConnection])

    const handleClose = () => {
        props.changeOpenState(false)
    };

    const deleteCategory = () => {
        const postData = {
            CATEGORY_ID: props.data.CATEGORY_ID
        };
        dispatch(Actions.http.connection.category.deleteCategory(postData));
    };


    return (
        <Dialog open={props.isOpen} onClose={handleClose} aria-labelledby="form-dialog-title"
                maxWidth={"sm"}
                fullWidth={true}
        >
            <DialogTitle id="form-dialog-title">カテゴリの削除</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    カテゴリを削除します。よろしいですか？
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    キャンセル
                </Button>
                <Button onClick={deleteCategory} color="primary">
                    削除
                </Button>
            </DialogActions>
        </Dialog>
    );
}


CategoryDeleteDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    changeOpenState: PropTypes.func.isRequired
};


CategoryDeleteDialog.defaultProps = {
    isOpen: false,
    changeOpenState: () => console.warn("need changeOpenState func")
};