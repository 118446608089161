import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import {createMuiTheme, makeStyles, ThemeProvider} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import LeftMenu from "./menu/LeftMenu";
import {useDispatch, useSelector} from "react-redux";
import * as Actions from "../flex/Actions";
import CategoryEffectComponent from "../EffectComponent/CategoryEffectComponent";
import {Route,Switch} from "react-router-dom";
import Topics from "./topics/Topics";
import Category from "./category/Category";
import Templates from "./templates/Templates";
import ContentNavigation from "./ContentNavigation";
import Settings from "./settings/Settings";

import packageJson from '../../package.json';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'KnowledgeSense SignageManager Ver.'}{packageJson.version}<br />
            {' Copyright © '}{new Date().getFullYear()}{' '}
            <a href={"https://siba-service.jp/"} style={{textDecoration:"none",color:"#555555"}}>Siba Service</a>
            {' Inc. All Rights Reserved.'}
        </Typography>
    );
}

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        paddingTop: 64,
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: 'auto',
        background: "white"
    },
    container: {
        maxWidth: "100%",
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

export default function Main() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const categoryConnection = useSelector(state => state.categoryConnection);


    useEffect(() => {
        console.log("run");
        dispatch(Actions.http.connection.category.getAllCategory());
    }, [])

    useEffect(() => {

    }, [categoryConnection]);


    return (
        <div className={classes.root}>
            <CssBaseline/>

            <CategoryEffectComponent/>
            {/*
            <GlobalMenu/>
            */}
            <ThemeProvider theme={darkTheme}>
                <LeftMenu />
            </ThemeProvider>

            <main className={classes.content}>
                {/*
                <div className={classes.appBarSpacer}/>
                */}
                <Container maxWidth="lg" className={classes.container}>
                    <Switch>
                        <Route path={"/topics/:id"}>
                            <Topics />
                        </Route>
                        <Route path={"/template"}>
                            <Templates />
                        </Route>
                        <Route path={"/category"}>
                            <Category />
                        </Route>
                        <Route path={"/settings"}>
                            <Settings />
                        </Route>
                        <Route path={"*"}>
                            <ContentNavigation/>
                        </Route>
                    </Switch>
                    <Box pt={4}>
                        <Copyright/>
                    </Box>
                </Container>
            </main>
        </div>
    );
}

const darkTheme = createMuiTheme({
    palette: {
        type: 'dark'
    }
});
