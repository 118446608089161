import React, {useEffect, useState} from 'react';
import ContentHeader from "../ContentHeader";
import CategoryInterface from "./CatregoryInterface";

export default function Category() {
    return (
        <>
            <ContentHeader title="カテゴリ管理" breadCrumbItems={[
                {title:"カテゴリ管理",icon:"notification",path:"/cartegory/"}
            ]}/>
            <CategoryInterface/>

        </>
    );
}
