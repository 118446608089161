import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as Actions from "../flex/Actions";


export default function CategoryEffectComponent() {
    const categoryConnection = useSelector(state => state.categoryConnection)
    const categoryDeleteConnection = useSelector(state => state.categoryDeleteConnection)
    const categoryEditConnection = useSelector(state => state.categoryEditConnection)
    const dispatch = useDispatch();

    useEffect(() => {
        if(categoryConnection.meta.status === Actions.statusEnum.SUCCESS && categoryConnection.payload.response === "success"){
            dispatch(Actions.data.categoryList(categoryConnection.payload.data));
        }
    }, [categoryConnection]);


    //カテゴリの編集削除を行った際はリロードかける
    useEffect( ()=> {
        if(categoryDeleteConnection.meta.status === Actions.statusEnum.SUCCESS && categoryDeleteConnection.payload.response === "success"){
            dispatch(Actions.http.connection.category.getAllCategory());
        }
        if(categoryEditConnection.meta.status === Actions.statusEnum.SUCCESS && categoryEditConnection.payload.response === "success"){
            dispatch(Actions.http.connection.category.getAllCategory());
        }
    },[categoryDeleteConnection,categoryEditConnection])



    return null
}