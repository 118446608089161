import React from "react";
import './scss/App.scss';
import AuthMain from "./components/Auth/AuthWrapper";

import {createMuiTheme, makeStyles, ThemeProvider} from '@material-ui/core/styles';
import ErrorNotice from "./components/Error/ErrorNotice";
import TitleMonitor from "./utils/TitleMonitor";


function App() {
    return (
        <ThemeProvider theme={theme}>
            <TitleMonitor />
            <ErrorNotice/>
            <AuthMain/>
        </ThemeProvider>
    );
}

const theme = createMuiTheme({
    palette: {
        type: 'light'
    }
});

export default App;
