import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {Create} from "@material-ui/icons";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import TableContainer from "@material-ui/core/TableContainer";
import * as Actions from "../../flex/Actions";
import Chip from '@material-ui/core/Chip';
import TopicEditDialog from "./TopicEditDialog";
import CategoryDeleteDialog from "../category/CategoryDeleteDialog";
import TopicDeleteDialog from "./TopicDeleteDialog";
import CategoryEditDialog from "../category/CategoryEditDialog";
import TopicChoiceTemplateDialog from "./TopicChoiceTemplateDialog";
import Skeleton from "@material-ui/lab/Skeleton";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {TopicPatrolledEnum} from "../../utils/Helper";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 1200,
    },
    actionHeader: {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: theme.spacing(2),
    },
    margin: {
        marginRight: theme.spacing(1),
    },
}));


export default function TopicsInterface() {
    const classes = useStyles();
    let {id} = useParams();
    const dispatch = useDispatch();
    const topicList = useSelector(state => state.topicList);
    const topicGetConnection = useSelector(state => state.topicGetConnection)

    const [isOpenEditDialog, setOpenEditDialog] = useState(false);
    const [isOpenDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isOpenFromTemplate, setOpenFromTemplate] = useState(false);
    const [isEditMode, setEditMode] = useState(false);
    const [data, setData] = useState({});
    const [array, setArray] = useState([]);
    const [template, setTemplate] = useState("");

    const [loading, setLoading] = useState(false);

    //topic取得部の終了の際、利用するarrayのパラメータを変更
    useEffect(() => {
        console.log(topicList);
        if (topicList.payload.hasOwnProperty(id)) {
            setArray(topicList.payload[id]);
        }
    }, [topicList])

    //id要素があれたときにtopicsを再読み込み
    useEffect(() => {
        const params = {CATEGORIES: [`${id}`]}
        dispatch(Actions.http.connection.topics.getTopics(params))
    }, [id]);

    useEffect(() => {
        setLoading(topicGetConnection.meta.fetch);
    }, [topicGetConnection])

    return (
        <>
            <div className={classes.actionHeader}>
                <Button
                    variant="contained"
                    className={classes.button}
                    startIcon={<Create/>}
                    color={"primary"}
                    disableElevation
                    onClick={() => {
                        /*
                        setEditMode(false);
                        setOpenEditDialog(true)
                        */
                        setOpenFromTemplate(true);
                    }}>
                    新しい記事を作成
                </Button>
            </div>

            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell width={60}>No</TableCell>
                            <TableCell width={120}>配信状況</TableCell>
                            <TableCell width={80}>承認</TableCell>
                            <TableCell>タイトル</TableCell>
                            <TableCell width={150}>配信日程</TableCell>
                            <TableCell width={200}>追加/編集 日時</TableCell>
                            <TableCell width={200}>作成者/編集者</TableCell>
                            <TableCell width={80}> </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ?
                            <TableRow>
                                <TableCell><Skeleton variant="text" animation={"wave"}/></TableCell>
                                <TableCell><Skeleton variant="text" animation={"wave"}/></TableCell>
                                <TableCell><Skeleton variant="text" animation={"wave"}/></TableCell>
                                <TableCell><Skeleton variant="text" animation={"wave"}/></TableCell>
                                <TableCell><Skeleton variant="text" animation={"wave"}/></TableCell>
                                <TableCell><Skeleton variant="text" animation={"wave"}/></TableCell>
                                <TableCell><Skeleton variant="text" animation={"wave"}/></TableCell>
                            </TableRow>
                            :
                            array.map((row, index) => (
                                <TableRow key={index} hover={true}>
                                    <TableCell>
                                        {row.TOPIC_ID}
                                    </TableCell>
                                    <TableCell component={"th"}>
                                        {row.CAST_STATUS === "0" && (
                                            <Chip size={"small"} label={"配信中"}
                                                  color="primary"/>
                                        )}
                                        {row.CAST_STATUS === "1" && (
                                            <Chip size={"small"} label={"配信前"}/>
                                        )}
                                        {row.CAST_STATUS === "2" && (
                                            <Chip size={"small"} label={"配信終了"} color="secondary"/>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {(() => {
                                            switch (row.TOPIC_PATROLLED) {
                                                case TopicPatrolledEnum.ACCEPTED:
                                                    return (
                                                        <Chip size={"small"} label={"承認"} color={"primary"}/>
                                                    );
                                                case TopicPatrolledEnum.AUTO_ACCEPTED:
                                                    return (
                                                        <Chip size={"small"} label={"自動承認"} color={"primary"}/>
                                                    );
                                                case TopicPatrolledEnum.NOTYET:
                                                    return (
                                                        <Chip size={"small"} label={"未確認"}/>
                                                    );
                                                case TopicPatrolledEnum.REJECTED:
                                                    return (
                                                        <Chip size={"small"} label={"却下"} color={"secondary"}/>
                                                    );
                                                default:
                                                    return (
                                                        <Chip size={"small"} label={"未確認"} color={"secondary"}/>
                                                    );
                                            }
                                        })()}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.TOPIC_TITLE}
                                    </TableCell>

                                    <TableCell>
                                        開始: {row.TOPIC_AVAILABLE_FROM}<br/>
                                        終了: {row.TOPIC_AVAILABLE_TO}
                                    </TableCell>
                                    <TableCell>
                                        {moment(row.TOPIC_CREATED).format("YYYY-MM-DD HH:mm")}<br/>
                                        {moment(row.TOPIC_EDITED).format("YYYY-MM-DD HH:mm")}
                                    </TableCell>
                                    <TableCell onMouseEnter={() => {
                                        console.log("onHover")
                                    }} onMouseLeave={() => console.log("onLeave")}>
                                        {row.hasOwnProperty("CREATOR") ? row.CREATOR.DISPLAY_NAME : ""}<br/>
                                        {row.hasOwnProperty("EDITOR") ? row.EDITOR.DISPLAY_NAME : ""}<br/>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Tooltip title="編集" aria-label="edit">
                                            <IconButton aria-label="edit" className={classes.margin}
                                                        size={"small"}
                                                        onClick={() => {
                                                            setData(row);
                                                            setEditMode(true);
                                                            setOpenEditDialog(true);
                                                        }
                                                        }>
                                                <EditIcon/>
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title="削除" aria-label="delete">
                                            <IconButton aria-label="delete" className={classes.margin}
                                                        size={"small"}
                                                        onClick={() => {
                                                            setData(row);
                                                            setOpenDeleteDialog(true)
                                                        }}>
                                                <DeleteForeverIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <TopicEditDialog isEditMode={isEditMode} data={data} isOpen={isOpenEditDialog}
                             changeOpenState={(value) => {
                                 setOpenEditDialog(value);
                                 setTemplate(null);
                             }} template={template}/>

            <TopicDeleteDialog data={data} isOpen={isOpenDeleteDialog}
                               changeOpenState={(value) => setOpenDeleteDialog(value)}/>

            <TopicChoiceTemplateDialog isOpen={isOpenFromTemplate}
                                       changeOpenState={(value) => setOpenFromTemplate(value)}
                                       setTemplate={(value) => {
                                           setTemplate(value);
                                           setOpenFromTemplate(false);
                                           setEditMode(false);
                                           setOpenEditDialog(true);
                                       }}
            />

        </>
    );
}