import React, {useEffect, useState} from 'react';
import LoginInterface from "./LoginInterface";
import * as Actions from "../../flex/Actions";
import {useDispatch} from "react-redux";
import {Switch} from "react-router-dom";
import AuthEffectComponent from "../../EffectComponent/AuthEffectComponent";

export default function Login() {

    return (
        <>
            <LoginInterface/>
        </>
    );
}
