import {useEffect} from "react";
import * as Helper from "./Helper";

export default function TitleMonitor() {

    useEffect(() => {
        document.title = `${Helper.generateTenantKey().name} - SignageManager KnowledgeSense`
    },[])


    return null;
}