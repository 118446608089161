import {useDispatch} from "react-redux";
import React from "react";
import {useParams} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles({
    contentHeader: {
        height: "130px",
        display: "flex",
        flexDirection: "column",
        alignItems: "streatch",
        padding: "10px",
        marginBottom: "20px",
        borderBottom: "1px solid #d9d9d9"
    },
    contentHeaderTitle: {
        flexGrow: "1",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        fontWeight: "500"
    }
});

function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
}

export default function ContentHeader(props) {
    const classes = useStyles();
    let { id } = useParams();
    const dispatch = useDispatch();

    return (
        <div id="content-header" className={classes.contentHeader}>
            <h1 className={classes.contentHeaderTitle}>{props.title}</h1>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                <Link color="inherit" href={"/"} onClick={handleClick}>
                    {"ホーム"}
                </Link>
                {props.breadCrumbItems.map((value,index) => {
                    return (
                        <Link key={index} color="inherit" href={value.path} onClick={handleClick}>
                            {value.title}
                        </Link>
                    )
                })}
            </Breadcrumbs>
        </div>
    );
}